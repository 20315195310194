import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { generateBackground } from 'src/app/adminPortal/shared/util';
import { Whitelabel } from 'src/types';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'zlo-background',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="grid-container full grid-x align-middle align-center page-container"
      [ngStyle]="generateBackgroundGradient()"
    >
      <ng-content></ng-content>
    </div>
  `,
  styles: ``,
  imports: [NgStyle]
})
export class BackgroundComponent {
  @Input() backgroundColors: Whitelabel['colorPalette']['pageBackground'];

  generateBackgroundGradient() {
    const whiteLabel = {
      ...generateBackground(this.backgroundColors),
      'min-height': '100vh',
      position: 'fixed',
      'z-index': '-1',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      opacity: '1'
    };

    return whiteLabel;
  }
}
